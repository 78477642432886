$(document).ready(function(){
    $(document).on('change', '.iframe_block select#project', function(){
        let domain = $(this).val();
        var protocol = window.location.protocol;
        var url = protocol + "//" + domain;
        window.location.replace(url);
    });


    $(document).on('click', '.iframe_block .change-device', function(){
        var device_type = $(this).data('device_type');

        $('#iframe-container').removeClass('desktop')
        $('#iframe-container').removeClass('tablet-h')
        $('#iframe-container').removeClass('tablet-v')
        $('#iframe-container').removeClass('mobile')
        $('#iframe-container').addClass(device_type);

        $('#iframe_header').removeClass('desktop')
        $('#iframe_header').removeClass('tablet-h')
        $('#iframe_header').removeClass('tablet-v')
        $('#iframe_header').removeClass('mobile')
        $('#iframe_header').addClass(device_type);

        if($('#responsive-iframe').hasClass('editor') && (device_type == 'mobile' || device_type == 'tablet-v')){
            var iframe = document.getElementById("responsive-iframe");
            iframe.src = iframe_url;
            iframe.className  = "";
        }
    });

    $(document).on('change', 'select[name="color_theme"]', function(){
        $(this).closest('form').submit();
    });



})